import { useEffect, useState } from 'react';
import { ItemInformationView } from './ItemInformationView';
import { useIntl } from 'react-intl';
import { EditableStatusFieldProps } from '../EditionStatusField/EditableStatusField.types';
import { ItemInformationProps } from './ItemInformation.types';

export const ItemInformation = ({ data, isLoading }: ItemInformationProps) => {
  const { formatMessage } = useIntl();

  const [isEditing, setIsEditing] = useState(false);
  const [sku, setSKU] = useState(data?.sku);
  const [status, setStatus] = useState(data?.status);
  const [warningStatus, setWarningStatus] = useState(false);

  const onEdit = () => {
    setIsEditing(true);
  };

  const onSave = () => {
    setIsEditing(false);
    setWarningStatus(false);
  };

  const onCancel = () => {
    setIsEditing(false);
    setWarningStatus(false);
  };

  const onChangeSKU = (value: string) => {
    setSKU(value);
  };

  useEffect(() => {
    setSKU(data?.sku);
    setStatus(data?.status);
  }, [data]);

  useEffect(() => {
    if (!isEditing) {
      setWarningStatus(false);
    }
  }, [isEditing]);

  const onChangeStatus = (value: EditableStatusFieldProps['value']) => {
    const shouldWarn = status === 'ENABLED' && value === 'DISABLED';
    setWarningStatus(shouldWarn);
    setStatus(value);
  };

  const attributesData = {
    sku: {
      texts: {
        title: formatMessage({ id: 'ItemDetailsPage.SKU' }),
      },
      value: sku,
      onChange: onChangeSKU,
      hasError: false,
    },
    productVariant: {
      texts: {
        title: formatMessage({ id: 'ItemDetailsPage.PRODUCT_VARIANT' }),
      },
      value:
        data?.product?.name && data?.variant?.name
          ? `${data?.product?.name} / ${data?.variant?.name}`
          : undefined,
    },
    seller: {
      texts: {
        title: formatMessage({ id: 'ItemDetailsPage.SELLER' }),
      },
      value: data?.vendorName,
    },
    sellerId: {
      texts: {
        title: formatMessage({ id: 'ItemDetailsPage.SELLER_ID' }),
        tooltip: formatMessage({ id: 'ItemDetailsPage.SELLER_ID_TOOLTIP' }),
      },
      value: data?.vendorId,
    },
    sellerItemId: {
      texts: {
        title: formatMessage({ id: 'ItemDetailsPage.SELLER_ITEM_ID' }),
        tooltip: formatMessage({ id: 'ItemDetailsPage.SELLER_ITEM_ID_TOOLTIP' }),
      },
      value: data?.vendorItemId,
    },
    status: {
      texts: {
        title: formatMessage({ id: 'ItemDetailsPage.STATUS' }),
        enabled: formatMessage({ id: 'ItemDetailsPage.ENABLED' }),
        disabled: formatMessage({ id: 'ItemDetailsPage.DISABLED' }),
        warningMessage: formatMessage({ id: 'ItemDetailsPage.STATUS_MESSAGE' }),
      },
      value: status,
      hasError: warningStatus,
      onChange: onChangeStatus,
    },
  };

  const sectionTexts = {
    title: formatMessage({ id: 'ItemDetailsPage.ITEM_INFORMATION' }),
    cancel: formatMessage({ id: 'ItemDetailsPage.CANCEL' }),
    saveChanges: formatMessage({ id: 'ItemDetailsPage.SAVE_CHANGES' }),
  };

  return (
    <ItemInformationView
      data={attributesData}
      sectionTexts={sectionTexts}
      onSave={onSave}
      onCancel={onCancel}
      onEdit={onEdit}
      isEditing={isEditing}
      isEditionDisabled={isLoading}
    />
  );
};
