import { Card, Input } from '@components';
import { ReactElement, useCallback, useMemo } from 'react';
import { FormatDateOptions, useIntl } from 'react-intl';

export interface ActivityHistoryProps {
  itemDetails: ItemDetailsResponse;
  isLoading: boolean;
}

export function ActivityHistory({ itemDetails }: Readonly<ActivityHistoryProps>): ReactElement {
  const { formatMessage, formatDate } = useIntl();

  const handleFormatDate = useCallback(
    (date: string) => {
      const dateOptions: FormatDateOptions = {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      };

      return formatDate(new Date(date), dateOptions);
    },
    [formatDate]
  );

  const texts = useMemo(() => {
    if (!itemDetails?.audit) {
      return {
        created: '',
        updated: '',
      };
    }

    const { createdBy, updatedBy, createdDate, updatedDate } = itemDetails.audit;

    const created = formatMessage(
      { id: 'ItemDetailsPage.ACTIVITY_HISTORY' },
      { username: createdBy, date: handleFormatDate(createdDate) }
    );
    const updated = formatMessage(
      { id: 'ItemDetailsPage.ACTIVITY_HISTORY' },
      { username: updatedBy, date: handleFormatDate(updatedDate) }
    );

    return {
      created,
      updated,
    };
  }, [itemDetails, formatMessage, handleFormatDate]);

  const IS_EDITING = false;

  return (
    <div style={{ width: '100%' }} data-testid="item-details-page__grid-item-audition-log">
      <Card
        id="item-details-page__card-audition-log"
        border="medium"
        elevated="small"
        title={formatMessage({ id: 'ItemDetailsPage.TITLE_ACTIVITY_HISTORY' })}
      >
        <Input
          id="item-details-page__input-audition-log-updated-by"
          label={formatMessage({ id: 'ItemDetailsPage.LABEL_LAST_BY' })}
          viewMode={!IS_EDITING}
          text={texts.created}
          mb="6"
        />

        <Input
          id="item-details-page__input-audition-log-created-by"
          label={formatMessage({ id: 'ItemDetailsPage.LABEL_CREATED_BY' })}
          viewMode={!IS_EDITING}
          text={texts.updated}
        />
      </Card>
    </div>
  );
}
