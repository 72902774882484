import { styled } from '@hexa-ui/theme';

export const FieldContainer = styled('div', {
  minHeight: '64px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const RadioContainer = styled('div', {
  display: 'flex',
  gap: '24px',
});

export const FieldTitle = styled('div', {
  display: 'flex',
  gap: '10px',
});

export const FieldValue = styled('div', {
  minHeight: '40px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyItems: 'center',
});
