import { Avatar as AvatarHexa } from '@hexa-ui/components';
import { Skeleton } from 'components';
import { AvatarProps } from 'components/avatar/Avatar.d';
import { css, style } from 'components/avatar/Avatar.styles';

const Avatar = ({
  type = 'rounded',
  size = 'xxlarge',
  image,
  children,
  variantType = 'image',
  disabled = false,
  id,
}: AvatarProps): React.ReactElement => {
  return (
    <Skeleton
      id={`skeleton-${id}`}
      variant="image"
      height="64px"
      width="64px"
      imageShape="rectangle"
    >
      <AvatarHexa
        id={id}
        data-testid={id}
        size={size}
        image={image}
        variantType={variantType}
        disabled={disabled}
        style={style(type)}
        css={css(type)}
        background="neutral0"
      >
        {children}
      </AvatarHexa>
    </Skeleton>
  );
};

export default Avatar;
