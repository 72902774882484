import { FeatureToggleProvider } from '@/providers/FeatureToggleProvider/FeatureToggleProvider';
import { InterPageActionProvider } from '@/providers/InterPageActionProvider/InterPageActionProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useAnalyticsService } from 'admin-portal-shared-services';
import { ApiProvider } from 'context/api';
import { ReactElement } from 'react';
import { CustomGridContainer, GlobalStyles } from './GlobalStyles.styles';
import Router from './Router';
import { EnvConfig, EnvProvider } from './envProvider';
import { IntlProvider } from './i18n';

interface EnvProps extends EnvConfig {
  CMS_HOST: string;
  CMS_TOKEN: string;
  optimizelyKey: string;
  segmentKey: string;
}

const queryClient = new QueryClient();

export default function App(props: Readonly<EnvProps>): ReactElement {
  const wrapperProps = { ...props, CMS_HOST: '', CMS_TOKEN: '' };

  const analyticsService = useAnalyticsService();
  analyticsService.load(wrapperProps.segmentKey);

  return (
    <QueryClientProvider client={queryClient}>
      <EnvProvider env={wrapperProps}>
        <FeatureToggleProvider optimizelyKey={wrapperProps.optimizelyKey}>
          <IntlProvider>
            <GlobalStyles />
            <CustomGridContainer type="fluid" sidebar>
              <ApiProvider>
                <InterPageActionProvider>
                  <Router />
                </InterPageActionProvider>
              </ApiProvider>
            </CustomGridContainer>
          </IntlProvider>
        </FeatureToggleProvider>
      </EnvProvider>
    </QueryClientProvider>
  );
}
