import { Select } from '@components/Select/Select';
import { useIntl } from 'react-intl';
import { GenericInputProps } from '../../ItemType.types';

interface SingleVariantProps {
  multiple?: false;
  value?: string;
}
interface MultipleVariantProps {
  multiple: true;
  displayText: string;
  value: Array<string>;
}

const ListInput = ({
  name,
  attribute,
  isEditing,
  onChange,
  value,
  errorMessage,
}: GenericInputProps): React.ReactElement => {
  const { formatMessage } = useIntl();
  const multipleProps: SingleVariantProps | MultipleVariantProps = attribute.multipleSelection
    ? {
        multiple: true,
        displayText: `${attribute.name.toLowerCase()} ${formatMessage({
          id: 'ItemDetailsPage.SELECTED',
        })}`,
        value: value as Array<string>,
      }
    : {
        multiple: false,
        value: value as string,
      };

  return (
    <Select
      selectProps={{
        label: attribute.name,
        error: errorMessage,
        onChange,
        placeholder: formatMessage({ id: 'ItemDetailsPage.SELECT_AN_OPTION' }),
        width: '100%',
        name: name,
        ...multipleProps,
      }}
      id={attribute.attributeId}
      options={attribute.allowedValues.map((option) => ({ value: option, label: option }))}
      required={attribute.isRequired}
      viewMode={!isEditing}
      tooltipText={attribute.description}
    />
  );
};

export { ListInput };
