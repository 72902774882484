import { styled } from '@hexa-ui/theme';
import { ListItem } from '@hexa-ui/components';
import { generatedVariants } from 'components/utils/generateVariants';

export const ListContainerWrapper = styled('div', {
  '&>ul': {
    listStyle: 'none',
  },
});

export const ListItemWrapper = styled(ListItem, {
  borderColor: '$neutral30',
  borderWidth: '$hairline',
  borderStyle: 'solid',
  padding: '$2',
  borderRadius: '$4',

  variants: {
    ...generatedVariants,
  },
});
