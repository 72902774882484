import { useAuthenticationService } from 'admin-portal-shared-services';
import Api from 'Api/Api';
import { useApiContext } from 'context';
import { useEffect } from 'react';
import useSWR from 'swr';
import { v4 as uuid } from 'uuid';

const useApi = <ResponseT>(
  url?: string,
  fetcher?: (url: string) => Promise<ResponseT>,
  customHeaders?: Partial<EnhancedHeaders>
): UseApiResponse<ResponseT> => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

  const defaultHeaders: EnhancedHeaders = {
    country: userCountry || '',
    noorders: localStorage.getItem('noOrders') || '',
    'Accept-language': '',
    requestTraceId: uuid(),
  };

  const headers: Array<EnhancedHeaders> = [
    {
      ...defaultHeaders,
      ...customHeaders,
    },
  ];

  Api.enhanceService<Array<EnhancedHeaders>>(authentication, headers);

  const { data, error, isLoading } = useSWR<ResponseT>(url, fetcher, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });
  const { setIsLoading } = useApiContext();

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  return {
    data,
    error,
    isLoading,
  };
};

export default useApi;
