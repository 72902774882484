import { Label, Paragraph } from 'components';
import { InputWrapper } from 'components/input/Input.styles';
import { InputProps } from 'components/types/Input';

const Input = ({
  id,
  label,
  viewMode = false,
  text,
  disabled = false,
  ml,
  mb,
  mt,
  mr,
  className = 'input',
  tooltipText,
  tooltipPlacement,
}: InputProps): React.ReactElement => {
  return (
    <>
      {!viewMode ? (
        <InputWrapper
          id={id}
          data-testid={id}
          label={label}
          disabled={disabled}
          mr={mr}
          mt={mt}
          mb={mb}
          ml={ml}
          className={className}
        />
      ) : (
        <div className={className}>
          <Label
            id={id}
            text={label}
            tooltipText={tooltipText}
            tooltipPlacement={tooltipPlacement}
          />
          <Paragraph
            colorType={disabled ? 'disabled' : 'primary'}
            text={text}
            mr={mr}
            mt={mt}
            mb={mb}
            ml={ml}
          />
        </div>
      )}
    </>
  );
};

export default Input;
