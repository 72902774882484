import ListItem from '@components/list/ListItem';
import { styled } from '@hexa-ui/theme';
import { Image } from '@components';

export const ItemDetails = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  '& p': {
    maxHeight: 20,
  },
});

export const ListItemDetails = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '$4',
  gap: '$2',
});

export const StyledListItem = styled(ListItem, {
  padding: '$4',
});

export const StyledImage = styled(Image, {
  minWidth: '64px',
  minHeight: '64px',
});
