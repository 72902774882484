import { Heading } from 'components';
import { CardHeader, CardWrapper } from 'components/card/Card.styles';
import { CardProps } from 'components/types/Card.d';

const Card = ({
  id,
  border,
  elevated,
  children,
  title,
  headerAction,
  pl = '6',
  pr = '6',
  pt = '6',
  pb = '6',
}: CardProps): React.ReactElement => {
  return (
    <CardWrapper
      id={id}
      data-testid={id}
      border={border}
      elevated={elevated}
      pl={pl}
      pr={pr}
      pt={pt}
      pb={pb}
    >
      <CardHeader>
        {!!title && <Heading title={title} size="H3" mb="6" />}
        {headerAction}
      </CardHeader>

      {children}
    </CardWrapper>
  );
};

export default Card;
