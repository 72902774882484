import { AvatarProps, AvatarStyle } from 'components/avatar/Avatar.d';

export const style = (type: AvatarProps['type']): AvatarStyle => {
  const borderRadius = {
    rounded: '50%',
    quadratic: '0',
  };

  return {
    borderRadius: borderRadius[type],
  };
};

// Hexa UI library does not export CSSVariants type. That's why we are using any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const css = (type: AvatarProps['type']): any => {
  const borderRadius = {
    rounded: '50%',
    quadratic: '0',
  };

  return {
    '&>div': {
      borderRadius: borderRadius[type],
    },
  };
};
