import { Card } from '@components';
import { Alert } from '@hexa-ui/components';
import { EditionBar } from '../../../../components/editionBar/EditionBar';
import { EditableStatusField } from '../EditionStatusField/EditableStatusField';
import { EditableTextField } from '../EditableTextField/EditableTextField';
import { ItemInformationContent, ItemInformationFields } from './ItemInformation.styles';
import { ItemInformationViewProps } from './ItemInformation.types';

export const ItemInformationView = ({
  data,
  sectionTexts,
  onSave,
  onCancel,
  onEdit,
  isEditing,
  isEditionDisabled,
}: ItemInformationViewProps) => {
  const { sku, productVariant, seller, sellerId, sellerItemId, status } = data;

  return (
    <Card
      title={sectionTexts.title}
      id="item-details-page-item-information"
      border="medium"
      elevated="small"
      headerAction={
        <EditionBar
          texts={{
            save: sectionTexts.saveChanges,
            cancel: sectionTexts.cancel,
          }}
          onEdit={onEdit}
          onSave={onSave}
          onCancel={onCancel}
          isEditing={isEditing}
          isDisabled={isEditionDisabled}
        />
      }
    >
      <ItemInformationContent>
        <ItemInformationFields>
          <EditableTextField
            title={sku.texts.title}
            error={sku.texts.error}
            value={sku.value}
            hasError={sku.hasError}
            onChange={sku.onChange}
            isEditing={isEditing}
            showCopyButton
          />
          <EditableTextField
            title={productVariant.texts.title}
            value={productVariant.value}
            isEditing={false}
            disabled
          />
          <EditableTextField
            title={seller.texts.title}
            value={seller.value}
            isEditing={isEditing}
            disabled
          />
          <EditableTextField
            title={sellerId.texts.title}
            tooltip={sellerId.texts.tooltip}
            value={sellerId.value}
            isEditing={isEditing}
            disabled
          />
          <EditableTextField
            title={sellerItemId.texts.title}
            tooltip={sellerItemId.texts.tooltip}
            value={sellerItemId.value}
            isEditing={isEditing}
            disabled
            showCopyButton
          />
          <EditableStatusField
            title={status.texts.title}
            value={status.value}
            onChange={status.onChange}
            isEditing={isEditing}
            options={{
              ENABLED: {
                text: status.texts.enabled,
                value: 'ENABLED',
              },
              DISABLED: {
                text: status.texts.disabled,
                value: 'DISABLED',
              },
            }}
          />
        </ItemInformationFields>
        {status.hasError && (
          <Alert
            data-testid="status-alert"
            css={{ width: '100%', marginTop: '16px' }}
            type="warning"
            message={status.texts.warningMessage}
          />
        )}
      </ItemInformationContent>
    </Card>
  );
};
