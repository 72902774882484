import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export type RouteHistory = {
  to: string;
  from: string;
};

type RouterProps = {
  children: React.ReactElement | React.ReactElement[];
};

enum PageName {
  CATEGORY_TREE_PAGE = 'CATEGORY_TREE_PAGE',
  ITEM_DETAILS_PAGE = 'ITEM_DETAILS_PAGE',
  PRODUCT_DETAILS_PAGE = 'PRODUCT_DETAILS_PAGE',
  PRODUCT_LISTING_PAGE = 'PRODUCT_LISTING_PAGE',
  VARIANT_DETAILS_PAGE = 'VARIANT_DETAILS_PAGE',
}

enum PreviousPageName {
  BEES_HUB_HOMEPAGE = 'BEES_HUB_HOMEPAGE',
  CATEGORY_TREE_PAGE = 'CATEGORY_TREE_PAGE',
  ITEM_DETAILS_PAGE = 'ITEM_DETAILS_PAGE',
  PRODUCT_DETAILS_PAGE = 'PRODUCT_DETAILS_PAGE',
  PRODUCT_LISTING_PAGE = 'PRODUCT_LISTING_PAGE',
  VARIANT_DETAILS_PAGE = 'VARIANT_DETAILS_PAGE',
}

const defaultValues = {
  to: '/',
  from: '/',
};

const Pages = { ...PageName, ...PreviousPageName };

const pageList = {
  [Pages.BEES_HUB_HOMEPAGE]: '/',
  [Pages.PRODUCT_LISTING_PAGE]: '/catalog-admin/catalog/products',
  [Pages.VARIANT_DETAILS_PAGE]: '/catalog-admin/catalog/product/details/:id/variant/:id',
  [Pages.PRODUCT_DETAILS_PAGE]: '/catalog-admin/catalog/product/details/:id',
  ['CATALOG_LIST_PAGE_ITEMS']: '/catalog-admin/catalog/items',
  ['CATALOG_LIST_PAGE']: '/catalog-admin/catalog',
  ['CATALOG_ADMIN_ONE_ROOT']: '/catalog-admin/plp',
  ['CREATE_PRODUCT_PAGE']: '/catalog-admin/catalog/product/create',
  ['CREATE_VARIANT_PAGE']: '/catalog-admin/catalog/product/details/:id/variant/create',
  ['BULK_IMPORTED']: '/catalog-admin/bulk',
  ['IMPORT_FILE']: '/catalog-admin/bulk/create',
  ['CATEGORY_LIST']: '/catalog-admin/categories',
  ['CATEGORY_DETAILS']: '/catalog-admin/category/details/:id',
  ['CREATE_CATEGORY']: '/catalog-admin/category/create/:country/:store',
  ['ITEM_DETAILS']: '/catalog-admin/catalog/items/details/:country/:id',
};

const pageByPath = (path: string): string => {
  const page = Object.keys(pageList).find((key) => {
    const pagePath = pageList[key];
    if (/:([a-zA-Z]\w*)/.test(pagePath)) {
      const regex = new RegExp(pagePath.replace(/:(\w+)/g, '[0-9a-zA-Z]+'));
      return regex.test(path);
    }
    return pagePath === path;
  });
  if (!page) {
    throw new Error(`Page not found for path ${path}`);
  }
  return page;
};

export const RouterContext = createContext(defaultValues);

export const getPageName = (path: string): PageName => {
  if (path === '/') return null;
  return pageByPath(path) as PageName;
};

export const getPreviousPageName = (path: string): PreviousPageName => {
  if (path === '/') return null;
  return pageByPath(path) as PreviousPageName;
};

export const useRouterContext = (): RouteHistory => {
  const context = useContext(RouterContext);
  if (!context) {
    throw new Error('useRouterContext must be used within an RouterProvider');
  }
  return context;
};

export const RouterProvider = ({ children }: RouterProps): React.ReactElement => {
  const location = useLocation();
  const [route, setRoute] = useState({
    to: location.pathname,
    from: defaultValues.from,
  });

  useEffect(() => {
    if (location.pathname === route.to) return;
    setRoute({ to: location.pathname, from: route.to });
  }, [location]);

  return <RouterContext.Provider value={route}>{children}</RouterContext.Provider>;
};

export default RouterProvider;
