import { styled } from '@hexa-ui/theme';
import scrollStyle from 'utils/scrollStyle';

export const TableWrapper = styled('div', {
  maxHeight: '480px',
  overflow: 'hidden',
  '& > div > div > table > thead > tr': {
    boxShadow: 'none',
  },
  ' & > div > div > table > thead > tr > th': {
    position: 'sticky',
    top: '0',
    background: '#f2f2f2',
    zIndex: '9',
  },
  '& > div > div:nth-child(1)': {
    display: 'none',
  },
  '& > div > div:nth-child(2)': {
    boxSizing: 'content-box',
    overflow: 'auto',
    scrollStyle,
  },
  '& table': {
    height: 'auto',
  },
  '& tbody > tr:last-child': {
    border: 'none',
  },
  '& div::-webkit-scrollbar': {
    width: '10px',
  },
  '& div::-webkit-scrollbar-thumb': {
    background: '#dbdada',
    borderRadius: '5px',
    border: '3px solid white',
  },

  '& div::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '& div::-webkit-scrollbar-button': {
    backgroundColor: 'transparent',
    borderRadius: '5px',
    height: '5px',
  },
});

export const InfiniteLoadingStatus = styled('div', {
  display: 'flex',
  paddingTop: '$4',
  paddingLeft: '$6',
  gap: '$2',
  borderTop: '1px solid var(--colors-interfaceSurfaceSecondary)',
});
