import { Radio } from '@hexa-ui/components';
import { EditableStatusFieldProps } from './EditableStatusField.types';
import {
  FieldContainer,
  FieldTitle,
  FieldValue,
  RadioContainer,
} from './EditableStatusField.styles';
import { Paragraph, StatusIndicator } from '@components';

export const EditableStatusField = ({
  title,
  value,
  options,
  isEditing,
  onChange,
}: EditableStatusFieldProps) => {
  const renderEditionMode = () => {
    return (
      <Radio.Root defaultValue={value} onValueChange={onChange} data-testid="status-input">
        <RadioContainer>
          {Object.entries(options).map(([key, option]) => (
            <Radio.Item
              key={key}
              id={key}
              label={option.text}
              value={key}
              data-testid={`status-input-${key.toLowerCase()}`}
            />
          ))}
        </RadioContainer>
      </Radio.Root>
    );
  };

  const renderDisplayMode = () => {
    if (value && options[value]) {
      return <StatusIndicator status={value} id={'status-display'} text={options[value].text} />;
    } else {
      return <div data-testid="status-display">-</div>;
    }
  };

  return (
    <FieldContainer>
      <FieldTitle>
        <Paragraph data-testid="status-title" weight="semibold" text={title} />
      </FieldTitle>
      <FieldValue>{isEditing ? renderEditionMode() : renderDisplayMode()}</FieldValue>
    </FieldContainer>
  );
};
