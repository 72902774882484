import { Paragraph, StatusIndicator } from '@components';
import { Radio } from '@hexa-ui/components';
import omit from 'lodash/omit';
import { Controller, useFormContext } from 'react-hook-form';
import { FieldContainer, FieldTitle, RadioContainer } from './EditableStatusField.styles';
import { EditableStatusFieldProps } from './EditableStatusField.types';

export const EditableStatusField = ({
  name,
  title,
  options,
  isEditing,
}: EditableStatusFieldProps): JSX.Element => {
  const { control, setValue, watch } = useFormContext();
  const currentValue = watch(name);

  const renderEditionMode = () => {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const fieldWithoutRef = omit(field, 'ref');
          return (
            <Radio.Root
              {...fieldWithoutRef}
              onValueChange={(value) => setValue(name, value)}
              data-testid="status-input"
            >
              <RadioContainer>
                {Object.entries(options).map(([value, label]) => (
                  <Radio.Item
                    key={value}
                    id={value}
                    label={label}
                    value={value}
                    data-testid={`status-input-${value.toLowerCase()}`}
                  />
                ))}
              </RadioContainer>
            </Radio.Root>
          );
        }}
      />
    );
  };

  const renderDisplayMode = () => {
    if (currentValue && options[currentValue]) {
      return (
        <StatusIndicator status={currentValue} id={'status-display'} text={options[currentValue]} />
      );
    } else {
      return <div data-testid="status-display">-</div>;
    }
  };

  return (
    <FieldContainer>
      <FieldTitle>
        <Paragraph data-testid="status-title" weight="semibold" text={title} />
      </FieldTitle>
      {isEditing ? renderEditionMode() : renderDisplayMode()}
    </FieldContainer>
  );
};
