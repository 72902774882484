import { useAuthenticationService } from 'admin-portal-shared-services';
import Api from 'Api/Api';
import { v4 as uuid } from 'uuid';

const ApiService = (customHeaders?: CustomHeaders): typeof Api => {
  const authentication = useAuthenticationService();
  const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

  const defaultHeaders: EnhancedHeaders = {
    country: userCountry || '',
    noorders: localStorage.getItem('noOrders') || '',
    'Accept-language': '',
    requestTraceId: uuid(),
  };

  const headers: Array<CustomHeaders> = [
    {
      ...defaultHeaders,
      ...customHeaders,
    },
  ];

  Api.enhanceService<Array<CustomHeaders>>(authentication, headers);

  return Api;
};

export { ApiService };
