import { Button, IconButton } from '@hexa-ui/components';
import { Edit2 } from '@hexa-ui/icons';
import { EditionBarProps } from './EditionBar.types';
import { EditionBarContent } from './EditionBar.styles';

export const EditionBar = ({
  texts: { save, cancel },
  onEdit,
  onSave,
  onCancel,
  isSaving,
  isEditing,
  isDisabled,
}: EditionBarProps) => {
  return (
    <EditionBarContent>
      {isEditing ? (
        <>
          <Button
            data-testid="edition-bar-cancel"
            variant="secondary"
            disabled={isSaving}
            onClick={onCancel}
          >
            {cancel}
          </Button>
          <Button data-testid="edition-bar-save" isLoading={isSaving} onClick={onSave}>
            {save}
          </Button>
        </>
      ) : (
        <IconButton
          data-testid="edition-bar-edit"
          size="small"
          variant="secondary"
          icon={Edit2}
          onClick={onEdit}
          disabled={isDisabled}
        />
      )}
    </EditionBarContent>
  );
};
