import { useController, UseFormReturn } from 'react-hook-form';
import { FieldsMetaData, VALUE_PATH } from '../../constants';
import { FormData, GenericInputProps, InputValueTypes, NamePath } from '../../ItemType.types';
import { isEmpty } from '../../utils/utils';

export interface ControllerWrapperProps {
  form: UseFormReturn<FormData, undefined, undefined>;
  name: NamePath;
  attribute: ClassAttribute;
  isEditing: boolean;
}

export const ControllerWrapper = ({
  attribute,
  form,
  isEditing,
  name,
}: ControllerWrapperProps): React.ReactElement => {
  const { Component, formatValueBeforeChange, formatValueBeforeRender, type } =
    FieldsMetaData[attribute.type];

  const valuePathName = `${name}.${VALUE_PATH}` as NamePath;

  const { field, fieldState } = useController({
    name: valuePathName,
    control: form.control,
  });

  const handleChange = (value: InputValueTypes) => {
    const formattedValue = formatValueBeforeChange?.(value) ?? value;
    field.onChange(formattedValue);
  };

  const handleGetValue = () => {
    let formattedValue = field.value as ClassAttribute['value'];

    if (isEmpty(formattedValue) && !isEditing) {
      formattedValue = '-';
    }

    return formatValueBeforeRender?.(formattedValue, attribute, isEditing) ?? formattedValue;
  };

  const props: GenericInputProps = {
    value: handleGetValue(),
    onChange: handleChange,
    attribute,
    isEditing,
    type: type,
    name: valuePathName,
    errorMessage: fieldState.error?.message,
    hasError: !!fieldState.error,
    baseName: name,
    form,
  };

  return <Component {...props} />;
};
