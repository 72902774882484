import useApi from '@hooks/useApi/useApi';
import Api from 'Api';
import { BASE_MANAGEMENT_BFF_PATH, URLS } from 'Api/enum';
import { useCallback, useEffect, useState } from 'react';

export interface UseGetItemDetailsById {
  itemDetails: ItemDetailsResponse;
  isLoadingItemDetails: boolean;
  error: any;
}

export const useGetItemDetailsById = ({
  country,
  itemId,
}: {
  country: string;
  itemId: string;
}): UseGetItemDetailsById => {
  const [url, setUrl] = useState<string>('');
  const [headers, setHeaders] = useState<EnhancedHeaders | undefined>();

  const get = useCallback(
    () =>
      (url: string): Promise<ItemDetailsResponse> =>
        Api.get<ItemDetailsResponse>({
          url,
          throwOnError: true,
        }),
    []
  );

  useEffect(() => {
    if (country && itemId) {
      setUrl(`${BASE_MANAGEMENT_BFF_PATH}${URLS.ITEMS_DETAILS}/${itemId}`);
      setHeaders({ country });
    }
  }, [country, itemId]);

  const { isLoading, data, error } = useApi<ItemDetailsResponse>(url, get(), headers);

  return {
    itemDetails: data,
    isLoadingItemDetails: isLoading,
    error,
  };
};
