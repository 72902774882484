import { LoadingDots } from '@hexa-ui/components';
import {
  AlertOctagon,
  AlertTriangle,
  ArrowLeft,
  BookOpen,
  Check,
  Checkmark2,
  ChevronDown,
  ChevronUp,
  Download,
  Edit2,
  Home,
  Info,
  Plus,
  Trash2,
  TreeDiagram,
  X,
} from '@hexa-ui/icons';
import { getIconComponent } from 'components/icon/Icon.styles';
import { IconProps, IconsNames } from 'components/types/Icon';
import { ReactNode } from 'react';

const ICONS: { [key in IconsNames]: ReactNode } = {
  ArrowLeft: ArrowLeft,
  Check: Check,
  ChevronDown: ChevronDown,
  ChevronUp: ChevronUp,
  BookOpen: BookOpen,
  Plus: Plus,
  AlertOctagon: AlertOctagon,
  Trash2: Trash2,
  Download: Download,
  Edit2: Edit2,
  Checkmark2: Checkmark2,
  AlertTriangle: AlertTriangle,
  LoadingDots: LoadingDots,
  TreeDiagram: TreeDiagram,
  X: X,
  Info: Info,
  Home: Home,
};

const Icon = ({ name, size, className, color, ...props }: IconProps): React.ReactElement => {
  const CurrentComponent = ICONS[name];
  const IconComponent = getIconComponent(CurrentComponent, color);

  return <IconComponent size={size} className={className} {...props} data-testid={name} />;
};

export default Icon;
