import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { FieldsMetaData, GROUPED_ATTRIBUTES_PATH } from '../../constants';

export default function useFormSchema(): yup.ObjectSchema<
  {
    attributes: unknown[];
  },
  FormData
> {
  const { formatMessage } = useIntl();
  const getValueSchema = (attribute: ClassAttribute) => {
    const metadata = FieldsMetaData[attribute.type];
    let schema = metadata?.schema;
    schema = applyRequired(schema, attribute.isRequired);
    schema.transforms.push((value) => {
      if (typeof value === 'string') return value.trim();
      return value;
    });
    return schema;
  };

  const getUomSchema = (attribute: ClassAttribute) => {
    const schema = yup.string();
    const isRequired = !!(attribute.type === 'MEASURE' && attribute.isRequired);
    return applyRequired(schema, isRequired);
  };

  const applyRequired = (schema: yup.AnySchema, isRequired: boolean) =>
    isRequired ? schema.required(formatMessage({ id: 'ItemDetailsPage.REQUIRED_FIELD' })) : schema;

  const createItemDetailsAttributeSchema = (value: ClassAttribute) => {
    const valueSchema = getValueSchema(value);
    const uomSchema = getUomSchema(value);

    return yup.object<ClassAttribute>().shape({
      attributeId: yup.string(),
      defaultKey: yup.string(),
      description: yup.string(),
      isRequired: yup.boolean(),
      key: yup.string(),
      name: yup.string(),
      sortOrder: yup.number(),
      units: yup.array().of(yup.string()),
      multipleSelection: yup.boolean(),
      allowedValues: yup.array().of(yup.string()),
      type: yup.string<ATTRIBUTETYPE>(),
      value: valueSchema,
      uom: uomSchema,
      groupedAttributes: yup
        .array()
        .of(
          yup.lazy((value) =>
            createItemDetailsAttributeSchema(value).omit([GROUPED_ATTRIBUTES_PATH])
          )
        ),
    });
  };

  const mainSchema = yup.object<FormData>().shape({
    attributes: yup.array().of(yup.lazy((value) => createItemDetailsAttributeSchema(value))),
  });

  return mainSchema;
}
