import { ClipboardButton } from '@components/clipboardButton';
import { Tooltip } from '@hexa-ui/components';
import { EditableTextFieldProps } from './EditableTextField.types';
import {
  FieldContainer,
  FieldTitle,
  FieldValue,
  InfoIcon,
  InputText,
} from './EditableTextField.styles';
import { Paragraph } from '@components';

export const EditableTextField = ({
  title,
  value,
  error,
  disabled,
  tooltip,
  hasError,
  isEditing,
  showCopyButton,
  onChange,
}: EditableTextFieldProps) => {
  const renderTitle = () => (
    <FieldTitle data-testid="field-title">
      <Paragraph weight="semibold" text={title} />
      {!isEditing && value && tooltip && (
        <Tooltip placement="top" text={tooltip}>
          <InfoIcon size="medium" />
        </Tooltip>
      )}
    </FieldTitle>
  );

  const renderInputField = () => (
    <InputText
      data-testid="field-input"
      value={value}
      hasError={hasError}
      errorText={error}
      disabled={disabled}
      width={'100%'}
      // @ts-ignore
      sufix={showCopyButton && <ClipboardButton textToCopy={value} />}
      onChange={(e) => onChange(e.currentTarget.value)}
    />
  );

  const renderParagraph = () => <Paragraph data-testid="field-display" text={value || '-'} />;

  return (
    <FieldContainer>
      {renderTitle()}
      <FieldValue>{isEditing ? renderInputField() : renderParagraph()}</FieldValue>
    </FieldContainer>
  );
};
