import { RouterParams } from '@/Router';
import { Card } from '@components';
import { EditionBar } from '@components/editionBar/EditionBar';
import { usePatchItem } from '@hooks/query/usePatchItem/usePatchItem';
import {
  getPageName,
  getPreviousPageName,
  useRouterContext,
} from 'providers/RouterProvider/RouterProvider';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import useTrackingService from '../../../../hooks/useTrackingService/useTrackingService';
import { ItemTypeContent, ItemTypeFields } from './ItemType.styles';
import { Loading } from './components/Loading/Loading';
import { useDynamicForm } from './hooks/useDynamicForm/useDynamicForm';
import { handleGetPatchAttributes } from './utils/utils';

export interface ItemTypeProps {
  data: ItemDetailsResponse;
  isLoading: boolean;
}

export const ItemType = ({ data, isLoading }: ItemTypeProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const [isEditing, setIsEditing] = useState(false);
  const {
    render,
    form: {
      formState: { isValid, defaultValues },
      handleSubmit,
      getValues,
      reset,
    },
  } = useDynamicForm(data?.attributes, isEditing);

  const { country, itemId } = useParams<RouterParams>();
  const { updateItem, isLoading: isUpdating } = usePatchItem({
    country,
    id: itemId,
    successMessage: formatMessage({ id: 'ItemDetailsPage.SUCCESS_UPDATE_ITEM_TYPE' }),
    failMessage: formatMessage({ id: 'ItemDetailsPage.ERROR_UPDATE_ITEM_TYPE' }),
  });

  const analytics = useTrackingService().getAnalytics();
  const routerContext = useRouterContext();

  const startEditingEvent = () => {
    analytics.itemTypeEditionStarted({
      button_name: 'Edit',
      country: country,
      item_status: data.status,
      page_name: getPageName(routerContext.to),
      sku: data.sku as unknown as number,
      previous_page_name: getPreviousPageName(routerContext.from),
      product_name: data.product.name,
      seller_id: data.vendorId as unknown as number,
      seller_name: data.vendorName,
      variant_gtin: data.variant?.gtin as unknown as number,
      item_type_attributes: data.attributes as unknown as string,
    });
  };

  const itemTypeDiscardedEvent = () => {
    analytics.itemTypeEditionDiscarded({
      button_name: 'Cancel',
      country,
      page_name: getPageName(routerContext.to),
      previous_page_name: getPreviousPageName(routerContext.from),
    });
  };

  const itemTypeSubmittedEvent = () => {
    analytics.itemTypeEditionSubmitted({
      button_name: 'Update',
      country,
      item_status: data.status,
      page_name: getPageName(routerContext.to),
      sku: data.sku as unknown as number,
      previous_page_name: getPreviousPageName(routerContext.from),
      product_name: data.product.name,
      seller_id: data.vendorId as unknown as number,
      seller_name: data.vendorName,
      variant_gtin: data.variant?.gtin as unknown as number,
    });
  };

  const onEdit = () => {
    setIsEditing(true);
    startEditingEvent();
  };

  const onSave = async () => {
    handleSubmit(async (values) => {
      const patchAttributesBody = handleGetPatchAttributes(values.attributes);
      try {
        await updateItem({
          attributes: patchAttributesBody,
        });
      } catch (error) {
        reset();
      }

      setIsEditing(false);
    })();
    itemTypeSubmittedEvent();
  };

  const onCancel = () => {
    setIsEditing(false);
    reset();
    itemTypeDiscardedEvent();
  };

  const sectionTexts = {
    title: formatMessage({ id: 'ItemDetailsPage.ITEM_TYPE' }),
    description: formatMessage({ id: 'ItemDetailsPage.ITEM_DETAILS' }),
  };

  const currentValues = getValues();

  const isSubmitEnabled = useMemo(() => {
    const isDirty = JSON.stringify(defaultValues) !== JSON.stringify(currentValues);
    return isValid && isDirty;
  }, [isValid, defaultValues, currentValues]);

  const shouldShowCard = useMemo(() => {
    return isLoading || data?.attributes?.length;
  }, [data, isLoading]);

  return (
    <>
      {shouldShowCard && (
        <Card
          title={sectionTexts.title}
          id="item-details-page-item-type"
          border="medium"
          elevated="small"
          subTitle={data?.class?.name}
          description={sectionTexts.description}
          headerAction={
            <EditionBar
              isDisabled={false}
              isSubmitButtonDisabled={!isSubmitEnabled}
              isUpdating={isUpdating}
              onEdit={onEdit}
              onSubmit={onSave}
              onCancel={onCancel}
              isEditing={isEditing}
            />
          }
        >
          <ItemTypeContent>
            <ItemTypeFields>{isLoading ? <Loading /> : render()}</ItemTypeFields>
          </ItemTypeContent>
        </Card>
      )}
    </>
  );
};
