import { ListContainerProps } from 'components/types/ListContainer.d';
import { ListContainerWrapper } from './List.styles';

const ListContainer = ({ children, id }: ListContainerProps): React.ReactElement => {
  return (
    <ListContainerWrapper id={id} data-testid={id}>
      <ul>{children}</ul>
    </ListContainerWrapper>
  );
};

export default ListContainer;
