import { Button, IconButton } from '@hexa-ui/components';
import { Edit2 } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { EditionBarContent } from './EditionBar.styles';
import { EditionBarProps } from './EditionBar.types';

export const EditionBar = ({
  onEdit,
  onSubmit,
  onCancel,
  isEditing,
  isUpdating,
  isDisabled,
  isSubmitButtonDisabled,
}: EditionBarProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <EditionBarContent>
      {isEditing ? (
        <>
          <Button
            data-testid="edition-bar-cancel"
            variant="secondary"
            disabled={isUpdating}
            onClick={onCancel}
            type="button"
          >
            {formatMessage({ id: 'Components.CANCEL' })}
          </Button>
          <Button
            data-testid="edition-bar-save"
            isLoading={isUpdating}
            disabled={isSubmitButtonDisabled}
            onClick={onSubmit}
            type="submit"
          >
            {formatMessage({ id: 'Components.SAVE_CHANGES' })}
          </Button>
        </>
      ) : (
        <IconButton
          data-testid="edition-bar-edit"
          size="small"
          variant="secondary"
          icon={Edit2}
          onClick={onEdit}
          disabled={isDisabled}
        />
      )}
    </EditionBarContent>
  );
};
