import { Label } from '@components';
import { Select as BaseSelect, SelectProps as SelectBaseProps } from '@hexa-ui/components';
import { ReactElement } from 'react';
import InputViewMode from '../InputViewMode/InputViewMode';

interface SelectProps {
  viewMode?: boolean;
  selectProps?: SelectBaseProps;
  tooltipText?: string;
  options: { label: string; value: string }[];
  id: string;
  required?: boolean;
  hide?: boolean;
}

export function Select({
  selectProps,
  options,
  viewMode,
  id,
  required,
  hide,
  tooltipText,
}: SelectProps): ReactElement {
  if (hide) return null;
  return !viewMode ? (
    <div style={{ width: '100%' }}>
      <Label
        text={selectProps.label}
        id={id}
        isOptional={!required}
        isError={!!selectProps.error}
      />
      <BaseSelect.Root {...selectProps} label="">
        {options.map((option) => (
          <BaseSelect.Option key={option.value} value={option.value}>
            {option.label}
          </BaseSelect.Option>
        ))}
      </BaseSelect.Root>
    </div>
  ) : (
    <InputViewMode
      value={String(selectProps?.value)}
      label={selectProps?.label}
      id={id}
      tooltipText={tooltipText}
    />
  );
}
