import SelectorView from '@components/selectorView/SelectorView';
import { World } from '@hexa-ui/icons';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import useCountry from 'hooks/useCountry/useCountry';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  disabled?: boolean;
};

function CountrySelector({ disabled = false }: Readonly<Props>): React.ReactElement {
  const { formatMessage } = useIntl();
  const { selectedCountry, countryOptions, isLoadingCountry, setSelectedCountry } = useCountry();
  const toast = useToast();

  const onChangeCountry = (country: string) => setSelectedCountry(country);

  useEffect(() => {
    const isSelectedCountrySupported = countryOptions?.find(
      (country) => country.id === selectedCountry
    );
    if (!isLoadingCountry && selectedCountry && !isSelectedCountrySupported) {
      toast.notify({
        type: TypeToast.ERROR,
        message: formatMessage(
          {
            id: 'CountrySelector.COUNTRY_NOT_SUPPORTED',
          },
          {
            country: selectedCountry,
          }
        ),
      });
    }
  }, [selectedCountry, isLoadingCountry, countryOptions]);

  return (
    <SelectorView
      icon={<World />}
      options={countryOptions}
      defaultValue={selectedCountry}
      isLoading={isLoadingCountry}
      disabled={disabled}
      onChange={onChangeCountry}
      callback="country"
    />
  );
}

export default CountrySelector;
