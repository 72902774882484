import { Label, Paragraph } from 'components';
import { ListFieldsWrapper } from 'components/attributes/Attributes.styles';

export const Attributes = ({ id, data }: AttributesProps): React.ReactElement => {
  return (
    <ListFieldsWrapper id={id}>
      {Object.keys(data).map((key, idx) => (
        <div key={`${key}`}>
          <Label id={`${id}-label-${idx}`} text={key} />
          <Paragraph text={data[key].toString()} />
        </div>
      ))}
    </ListFieldsWrapper>
  );
};

export default Attributes;
