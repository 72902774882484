import { Input } from '@hexa-ui/components';
import { Info } from '@hexa-ui/icons';
import { styled } from '@hexa-ui/theme';

export const FieldTitle = styled('div', {
  display: 'flex',
  gap: '4px',
});

export const FieldValue = styled('div', {
  minHeight: '40px',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});

export const InputText = styled(Input, {
  height: '40px !important',
});

export const FieldContainer = styled('div', {
  minHeight: '64px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const InfoIcon = styled(Info, {
  color: 'rgba(20, 20, 20, 0.56)',
});
