const permissionConstants: { [key: string]: string } = {
  PRODUCTS_CREATE: 'CatalogAdmin.Products.Create',
  PRODUCTS_DELETE: 'CatalogAdmin.Products.Delete',
  PRODUCTS_READ: 'CatalogAdmin.Products.Read',
  PRODUCTS_UPDATE: 'CatalogAdmin.Products.Update',
  CATALOG_COUNTRIES_ALL_SCOPE: 'CatalogAdmin.CountriesAll.Execute',
  CATALOG_VENDORS_ALL_SCOPE: 'CatalogAdmin.VendorsAll.Execute',
};

export default permissionConstants;
