import { PROJECT_NAME } from '@config/featureToggles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createFeatureToggleServiceV2, useAnalyticsService } from 'admin-portal-shared-services';
import { ApiProvider } from 'context/api';
import { ReactElement } from 'react';
import { CustomGridContainer, GlobalStyles } from './GlobalStyles.styles';
import Router from './Router';
import { EnvConfig, EnvProvider } from './envProvider';
import { IntlProvider } from './i18n';
import { InterPageActionProvider } from './providers/InterPageActionProvider/InterPageActionProvider';

interface EnvProps extends EnvConfig {
  CMS_HOST: string;
  CMS_TOKEN: string;
  optimizelyKey: string;
  segmentKey: string;
}

const queryClient = new QueryClient();

export default function App(props: Readonly<EnvProps>): ReactElement {
  const wrapperProps = { ...props, CMS_HOST: '', CMS_TOKEN: '' };
  createFeatureToggleServiceV2(PROJECT_NAME, wrapperProps.optimizelyKey);
  const analyticsService = useAnalyticsService();
  analyticsService.load(wrapperProps.segmentKey);

  return (
    <QueryClientProvider client={queryClient}>
      <EnvProvider env={wrapperProps}>
        <IntlProvider>
          <GlobalStyles />
          <CustomGridContainer type="fluid" sidebar>
            <ApiProvider>
              <InterPageActionProvider>
                <Router />
              </InterPageActionProvider>
            </ApiProvider>
          </CustomGridContainer>
        </IntlProvider>
      </EnvProvider>
    </QueryClientProvider>
  );
}
