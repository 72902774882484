import defaultImage from 'assets/default-image.svg';
import { Skeleton } from 'components';
import { ImageProps } from 'components/image/Image.d';
import { ImageStyled } from 'components/image/Image.styles';

const Image = ({
  id,
  src,
  variant,
  defaultImageAltText = 'Default image',
  height,
  width,
  ...props
}: ImageProps): React.ReactElement => {
  return (
    <Skeleton
      id={`skeleton-${id}`}
      variant="image"
      height={height?.toString() || '376px'}
      width={height?.toString() || '376px'}
      imageShape="rectangle"
      addStyle="border"
    >
      <ImageStyled
        id={id}
        data-testid={id}
        src={src}
        variant={variant}
        height={height}
        width={width}
        {...props}
        customErrorImage={{
          alt: defaultImageAltText,
          src: defaultImage,
        }}
      />
    </Skeleton>
  );
};

export default Image;
