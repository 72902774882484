import {
  Breadcrumb,
  Card,
  CountrySelector,
  GridItem,
  PageHeader,
  PageHeaderContainer,
} from '@components';
import { useGetItemDetailsById } from '@hooks/query/useGetItemDetailsById/useGetItemDetailsById';
import { Details, DetailsColumn, PageContainer } from 'pages/pages.styles';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BASENAME, RouterParams, ROUTES } from 'Router';
import { ActivityHistory } from './components/ActivityHistory/ActivityHistory';
import { ItemInformation } from './components/ItemInformation/ItemInformation';
import { ProductVariantInformation } from './components/ProductVariantInformation/ProductVariantInformation';
import { Error404 } from '@hexa-ui/components';

function ItemDetailsPage(): ReactElement {
  const { formatMessage } = useIntl();
  const location = useLocation();

  const variantId = location.state?.variantId;
  const productId = location.state?.productId;

  const { country, itemId } = useParams<RouterParams>();

  const navigate = useNavigate();

  const { itemDetails, isLoadingItemDetails, error } = useGetItemDetailsById({ country, itemId });

  const pageProductListPath = formatMessage({ id: 'ProductListPage.TITLE' });
  const pageProductPath = formatMessage({ id: 'ProductPage.TITLE' });
  const pageVariantPath = formatMessage({ id: 'VariantPage.TITLE' });
  const pageItemsPath = formatMessage({ id: 'VariantPage.TITLE_ITEMS' });

  const breadCrumbPathDefault = [
    {
      linkTitle: pageItemsPath,
      linkKey: '1',
      linkUrl: `/${BASENAME}${ROUTES.ITEMS_LIST}`,
    },
  ];

  const breadCrumbVariantPath = [
    {
      linkTitle: pageProductListPath,
      linkKey: '1',
      linkUrl: `/${BASENAME}${ROUTES.PRODUCTS_LIST}`,
    },
    {
      linkTitle: pageProductPath,
      linkKey: '2',
      linkUrl: `/${BASENAME}${ROUTES.PRODUCT_DETAILS}/${productId}/${country}`,
    },
    {
      linkTitle: pageVariantPath,
      linkKey: '3',
      linkUrl: `/${BASENAME}${ROUTES.PRODUCT_DETAILS}/${productId}${ROUTES.VARIANT_DETAILS}/${variantId}/${country}`,
    },
  ];

  const breadCrumbPath =
    !!location.state?.variantId && !!location.state?.productId
      ? breadCrumbVariantPath
      : breadCrumbPathDefault;

  const previousPath = breadCrumbPath[breadCrumbPath.length - 1].linkUrl;

  if (error) {
    return (
      <Error404
        buttonClickHandler={() => navigate(previousPath)}
        buttonText={formatMessage({ id: 'ItemDetailsPage.ERROR_BUTTON' })}
        headerText={formatMessage({ id: 'ItemDetailsPage.ERROR_TITLE' })}
        subHeaderText={formatMessage({ id: 'ItemDetailsPage.ERROR_DESCRIPTION' })}
      />
    );
  }

  return (
    <PageContainer data-testid="item-details-page">
      <PageHeaderContainer>
        <PageHeader
          title={itemDetails?.sku}
          breadcrumb={
            <Breadcrumb
              currentPage={formatMessage({ id: 'ItemDetailsPage.TITLE' })}
              history={breadCrumbPath}
            />
          }
          prevPageUrl={previousPath}
        >
          <CountrySelector disabled />
        </PageHeader>
      </PageHeaderContainer>

      <Details type="fluid">
        <DetailsColumn type="left">
          <GridItem>
            <ItemInformation data={itemDetails} isLoading={isLoadingItemDetails} />
          </GridItem>
          <GridItem>
            <Card id="item-details-page-item-type" border="medium" elevated="small" />
          </GridItem>
        </DetailsColumn>
        <DetailsColumn type="right">
          <GridItem>
            <ProductVariantInformation itemDetails={itemDetails} />
          </GridItem>
          <GridItem>
            <ActivityHistory isLoading={isLoadingItemDetails} itemDetails={itemDetails} />
          </GridItem>
        </DetailsColumn>
      </Details>
    </PageContainer>
  );
}

export default ItemDetailsPage;
