import { IApi } from 'Api/Api';

export class Fetcher {
  constructor(private readonly apiService: IApi) {}

  get =
    <ResponseT, ParamsT = unknown>(params?: ParamsT) =>
    (url: string): Promise<ResponseT> =>
      this.apiService.get<ResponseT, ParamsT>({ url, params });
}
