import { getDataTestId } from '@utils/buildDataTestId';
import Paragraph from 'components/typography/Paragraph';
import { useIntl } from 'react-intl';
import { Box, BoxWrapper, StyledLabel } from './AttributesListReview.styles';

type AttributeValue = boolean | string | string[] | Record<string, string | string[]>;

interface AttributesListReviewProps {
  attributes: Record<string, AttributeValue>;
  groupAttribute?: boolean;
  'data-testid'?: string;
}

const AttributesListReview = ({
  attributes,
  groupAttribute,
  'data-testid': testId,
}: AttributesListReviewProps): React.ReactElement => {
  const { formatMessage } = useIntl();

  const getTextFromStringValue = (value: string): string => {
    if (value === 'true') {
      return formatMessage({ id: 'ProductPage.ATTRIBUTE_YES' });
    } else if (value === 'false') {
      return formatMessage({ id: 'ProductPage.ATTRIBUTE_NO' });
    } else {
      return value || '-';
    }
  };

  return (
    <BoxWrapper groupAttribute={groupAttribute}>
      {attributes &&
        Object.entries(attributes).map(([key, value]: [key: string, value: AttributeValue]) => (
          <Box key={key}>
            <StyledLabel id={key}>{key}</StyledLabel>
            {Array.isArray(value) && value.length > 0 && (
              <Paragraph
                data-testid={getDataTestId(testId, key, 'field')}
                text={value.length > 0 ? value.join(', ') : '-'}
              />
            )}
            {typeof value === 'string' && (
              <Paragraph
                data-testid={getDataTestId(testId, key, 'field')}
                text={getTextFromStringValue(value)}
              />
            )}
            {typeof value === 'object' && !Array.isArray(value) && value.value && value.uom && (
              <Paragraph
                data-testid={getDataTestId(testId, key, 'field')}
                text={`${value.value} ${value.uom}`}
              />
            )}
            {typeof value === 'object' && !Array.isArray(value) && !value.value && !value.uom && (
              <AttributesListReview
                groupAttribute
                attributes={value}
                data-testid={getDataTestId(testId, key)}
              />
            )}
            {typeof value === 'boolean' && (
              <Paragraph
                data-testid={getDataTestId(testId, key, 'field')}
                text={value ? 'Yes' : 'No'}
              />
            )}
            {typeof value === 'undefined' && (
              <Paragraph data-testid={getDataTestId(testId, key, 'field')} text="-" />
            )}
          </Box>
        ))}
    </BoxWrapper>
  );
};

export default AttributesListReview;
