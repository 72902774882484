import Paragraph from 'components/typography/Paragraph';
import { Box, BoxWrapper, StyledLabel } from './AttributesListReview.styles';
import { getDataTestId } from '@utils/buildDataTestId';

type AttributeValue = boolean | string | string[] | Record<string, string | string[]>;

interface AttributesListReviewProps {
  attributes: Record<string, AttributeValue>;
  groupAttribute?: boolean;
  'data-testid'?: string;
}

const AttributesListReview = ({
  attributes,
  groupAttribute,
  'data-testid': testId,
}: AttributesListReviewProps): React.ReactElement => {
  return (
    <BoxWrapper groupAttribute={groupAttribute}>
      {attributes &&
        Object.entries(attributes).map(([key, value]: [key: string, value: AttributeValue]) => (
          <Box key={key}>
            <StyledLabel id={key}>{key}</StyledLabel>
            {Array.isArray(value) && value.length > 0 && (
              <Paragraph
                data-testid={getDataTestId(testId, key, 'field')}
                text={value.length > 0 ? value.join(', ') : '-'}
              />
            )}
            {typeof value === 'string' && (
              <Paragraph data-testid={getDataTestId(testId, key, 'field')} text={value || '-'} />
            )}
            {typeof value === 'object' && !Array.isArray(value) && (
              <AttributesListReview
                groupAttribute
                attributes={value}
                data-testid={getDataTestId(testId, key)}
              />
            )}
            {typeof value === 'boolean' && (
              <Paragraph
                data-testid={getDataTestId(testId, key, 'field')}
                text={value ? 'Yes' : 'No'}
              />
            )}
            {typeof value === 'undefined' && (
              <Paragraph data-testid={getDataTestId(testId, key, 'field')} text="-" />
            )}
          </Box>
        ))}
    </BoxWrapper>
  );
};

export default AttributesListReview;
