import { AttributeToPatch } from '@services/items/ItemsService.types';
import { INT_REGEX } from '../constants';

export const handleFormatDateBeforeChange = (value: Date): string => value?.toISOString();
export const handleFormatNumberBeforeChange = (value: string): number => Number(value);
export const handleFormatIntBeforeChange = (value: string): number =>
  Number(value.replace(INT_REGEX, ''));
export const handleFormatBooleanBeforeChange = (value: string): boolean => value === 'true';
export const handleFormatBooleanBeforeRender = (value?: boolean): string => {
  if (typeof value !== 'boolean') return value;
  return value ? 'true' : 'false';
};
export const handleFormatListBeforeChange = (value: Array<string> | string): string =>
  Array.isArray(value) ? value.join(',') : value;

export const handleFormatListBeforeRender = (
  value: string,
  attribute: ClassAttribute
): string | string[] => {
  if (!value) return attribute.multipleSelection ? [] : value;
  return attribute.multipleSelection ? value.split(',') : value;
};

export const handleFormatMeasureBeforeRender = (
  value: number,
  attribute: ClassAttribute,
  isEditing: boolean
): string | number => {
  if (isEditing) return value;
  return value && attribute.uom ? `${value} ${attribute.uom}` : value;
};
export const handleFormatMeasureBeforeChange = (value: string): number => Number(value);

export const handleGetLocale = (locale: string): 'pt' | 'es' | 'en' => {
  const localeSerialization = {
    'pt-BR': 'pt',
    'en-US': 'en',
    'es-419': 'es',
  };
  return localeSerialization[locale] ?? localeSerialization['en-US'];
};

export const isEmpty = (value: unknown): boolean => {
  return value === '' || value === null || value === undefined || value === '-';
};

export const handleGetPatchAttributes = (attributes: ClassAttribute[]): AttributeToPatch[] => {
  const result: AttributeToPatch[] = [];

  attributes.forEach((attribute) => {
    if (!isEmpty(attribute.value)) {
      result.push({
        attributeId: attribute.attributeId,
        value: attribute.value,
        uom: attribute.uom,
      });
    }
    if (attribute.type === 'GROUP') {
      result.push({
        attributeId: attribute.attributeId,
        value: attribute.value,
        groupedAttributes: handleGetPatchAttributes(attribute.groupedAttributes),
      });
    }
  });
  return result;
};

export const handleSortOrder = (attributes: ClassAttribute[]): ClassAttribute[] => {
  return attributes.sort((el1, el2) => el1.sortOrder - el2.sortOrder);
};
