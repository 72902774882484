import { Heading, Label, Paragraph, Skeleton } from '@components';
import {
  AttributeGroup,
  GroupSection,
  ListFieldsWrapper,
} from 'components/attributes/Attributes.styles';

export const Attributes = ({ id, attributes }: AttributesProps): React.ReactElement => {
  if (!Array.isArray(attributes)) return <>-</>;

  const renderAttributeItem = ({
    label,
    value = '-',
    idx,
  }: {
    label: string;
    value: ClassAttribute['value'];
    idx: number;
  }) => (
    <div key={`${id}-${label}-${idx}`}>
      <Label id={`${id}-${label}-${idx}`} text={label} />
      <Paragraph text={value.toString()} />
    </div>
  );

  const renderAttributeGroup = ({
    title,
    attributes,
  }: {
    title: string;
    attributes: ClassAttribute[];
  }) => (
    <AttributeGroup key={title}>
      <Heading title={title} size="H5" />
      <GroupSection id={`${id}-group`}>
        {attributes?.map((attr, idx) =>
          renderAttributeItem({ label: attr.name, value: attr.value, idx })
        )}
      </GroupSection>
    </AttributeGroup>
  );

  return (
    <Skeleton
      id="skeleton-paragraph"
      width="120px"
      height="16px"
      imageShape="rectangle"
      variant="body"
    >
      <ListFieldsWrapper id={id}>
        {attributes?.map((attr, idx) => {
          const isGroupAttribute = attr.type === 'GROUP';
          return isGroupAttribute
            ? renderAttributeGroup({ title: attr.name, attributes: attr.groupedAttributes })
            : renderAttributeItem({ label: attr.name, value: attr.value, idx });
        })}
      </ListFieldsWrapper>
    </Skeleton>
  );
};

export default Attributes;
