import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { Resolver, useFieldArray, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { ControllerWrapper } from '../../components/ControllerWrapper/ControllerWrapper';
import { ATTRIBUTE_PATH } from '../../constants';
import { FormData, NamePath } from '../../ItemType.types';
import { handleSortOrder } from '../../utils/utils';
import useFormSchema from '../useFormSchema/useFormSchema';

interface UseDynamicFormReturn {
  render: () => JSX.Element[];
  form: UseFormReturn<FormData, unknown, undefined>;
}

export function useDynamicForm(
  attributes: ClassAttribute[] = [],
  isEditing: boolean
): UseDynamicFormReturn {
  const schema = useFormSchema();

  const sortedAttributes = handleSortOrder(attributes);

  const form = useForm<FormData>({
    defaultValues: { attributes: sortedAttributes },
    values: { attributes: sortedAttributes },
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema) as Resolver<FormData>,
  });

  const { fields } = useFieldArray({
    control: form.control,
    name: ATTRIBUTE_PATH,
  });

  const watchedValues = useWatch({
    name: ATTRIBUTE_PATH,
    defaultValue: fields,
    control: form.control,
  });

  const getRender = useCallback(
    (attributes: ClassAttribute[]) => {
      return attributes?.map((attribute, index) => {
        const props = {
          form,
          name: `${ATTRIBUTE_PATH}.${index}` as NamePath,
          attribute,
          isEditing,
        };

        return <ControllerWrapper {...props} key={attribute.attributeId} />;
      });
    },
    [form, isEditing]
  );

  const renderComponents = useCallback(() => {
    return getRender(watchedValues);
  }, [watchedValues, getRender]);

  return { render: renderComponents, form };
}
