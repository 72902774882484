export const getDataTestId = (prefix: string, id: string, suffix?: string) => {
  const normalizeString = (string: string) => {
    return string
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .replace(/\s+/g, '-')
      .toLowerCase();
  };
  const dataTestId = `${prefix}-${normalizeString(id)}`;
  if (suffix) {
    return `${dataTestId}-${suffix}`;
  }
  return dataTestId;
};
